<template>
<div>
    <CCol sm="12">
        <CCardBody class="d-flex flex-column">
                <div role="group" class="row form-group">
                    <!-- Go back -->
                    <span class="btn btn-primary button cursor-pointer my-4 " @click="$router.push({name: 'ViewProperty', params: { property: baseProperty } })">&lt; Voltar</span>
                    <div class="row">
                        <!-- Title -->
                        <h2 v-if="baseProperty" class="secondary-text-color font-weight-bold col-lg-9 col-md-12 col-sm-12">Editar Imóvel</h2>
                        <h2 v-else class="secondary-text-color font-weight-bold col-lg-9 col-md-12 col-sm-12">Adicionar Imóvel</h2>
                        <!-- Owership selector -->
                        <span id="ownership-selector" class="col-lg-3 col-md-12 col-sm-12 cursor-pointer" :class="{ 'is-invalid': submitted && hasError('Ownership') }"> <span @click="property.ownership = 'Personal'" v-bind:class="{ 'active': property.ownership == 'Personal' }">Pessoal</span> | <span @click="property.ownership = 'Business'" v-bind:class="{ 'active': property.ownership == 'Business' }">Empresa</span></span>
                    </div>
                    <!-- Name -->
                    <div class="col-lg-4 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties">Nome do Imóvel</label>
                        <input type="text" v-model="property.name" class="form-control" :class="{ 'is-invalid': submitted && hasError('Name') }"/>
                        <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                            <span v-if="!$v.property.name.required">É necessário o Nome do Imóvel</span>
                            <span v-if="errors.Name">{{errors.Name}}</span>
                        </div>
                    </div>
                    <!-- Address -->
                    <div class="col-lg-8 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties">Morada do Imóvel</label>
                        <input type="text" v-model="property.address" class="form-control" :class="{ 'is-invalid': submitted && hasError('Address') }"/>
                        <div v-if="submitted && hasError('Address')" class="invalid-feedback">
                            <span v-if="!$v.property.address.required">É necessário a Morada do Imóvel</span>
                            <span v-if="errors.Address">{{errors.Address}}</span>
                        </div>
                    </div>
                    <!-- Purchase date -->
                    <div class="col-lg-4 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties"> {{ ('Data de compra') }}</label>
                        <div class="form-control" :class="{ 'is-invalid': submitted && hasError('PurchaseDate') }">
                            <datepicker 
                                :placeholder="'DD/MM/YYYY'"
                                v-model="property.purchaseDate" 
                                :format="customFormatter" 
                                :calendar-button="true"
                                :calendar-button-icon="'bi bi-calendar3'"
                                >
                            </datepicker>
                        </div>
                        <div v-if="submitted && hasError('PurchaseDate')" class="invalid-feedback">
                            <span v-if="!$v.property.purchaseDate.required">É necessária a Data de Compra do Imóvel</span>
                            <span v-if="errors.PurchaseDate">{{errors.PurchaseDate}}</span>
                        </div>
                    </div>
                    <!-- Purchase Amount -->
                    <div class="col-lg-4 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties">Valor de compra</label>
                        <input type="number" v-model="property.purchaseAmount" class="form-control" :class="{ 'is-invalid': submitted && hasError('PurchaseAmount') }"/>
                        <div v-if="submitted && hasError('PurchaseAmount')" class="invalid-feedback">
                            <span v-if="!$v.property.purchaseAmount.required">É necessário o Valor de Compra do Imóvel</span>
                            <span v-if="errors.PurchaseAmount">{{errors.PurchaseAmount}}</span>
                        </div>
                    </div>
                    <!-- Initial cost -->
                    <div class="col-lg-4 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties">Custo Inicial</label>
                        <input type="number" v-model="property.initialCost" class="form-control" :class="{ 'is-invalid': submitted && hasError('InitialCost') }"/>
                        <div v-if="submitted && hasError('InitialCost')" class="invalid-feedback">
                            <span v-if="!$v.property.initialCost.required">É necessário o Custo Inicial do Imóvel</span>
                            <span v-if="errors.InitialCost">{{errors.InitialCost}}</span>
                        </div>
                    </div>
                    <!-- Financing amount -->
                    <div class="col-lg-3 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties">Valor de Financiamento</label>
                        <input type="number" v-model="property.financingAmount" class="form-control" :class="{ 'is-invalid': submitted && hasError('FinancingAmount') }"/>
                        <div v-if="submitted && hasError('FinancingAmount')" class="invalid-feedback">
                            <span v-if="!$v.property.financingAmount.required">É necessário o Valor de Financiamento do Imóvel</span>
                            <span v-if="errors.FinancingAmount">{{errors.FinancingAmount}}</span>
                        </div>
                    </div>
                    <!-- Years of financing -->
                    <div class="col-lg-3 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties">Anos de Financiamento</label>
                        <input type="number" v-model="property.financingYears" class="form-control" :class="{ 'is-invalid': submitted && hasError('FinancingYears') }"/>
                        <div v-if="submitted && hasError('FinancingYears')" class="invalid-feedback">
                            <span v-if="!$v.property.financingYears.required">São necessários os Anos de Financiamento do Imóvel</span>
                            <span v-if="errors.FinancingYears">{{errors.FinancingYears}}</span>
                        </div>
                    </div>
                    <!-- Total Financing -->
                    <div class="col-lg-3 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties"> {{ ('Total de Financiamento') }}</label>
                        <input type="number" v-model="property.totalFinancing" class="form-control" :class="{ 'is-invalid': submitted && hasError('TotalFinancing') }"/>
                        <div v-if="submitted && hasError('TotalFinancing')" class="invalid-feedback">
                            <span v-if="!$v.property.totalFinancing.required">É necessário o Total de Financiamento do Imóvel</span>
                            <span v-if="errors.TotalFinancing">{{errors.TotalFinancing}}</span>
                        </div>
                    </div>
                    <!-- Equity value -->
                    <div class="col-lg-3 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-properties"> {{ ('Valor Patrimonial') }}</label>
                        <input type="number" v-model="property.equityValue" class="form-control" :class="{ 'is-invalid': submitted && hasError('EquityValue') }"/>
                        <div v-if="submitted && hasError('EquityValue')" class="invalid-feedback">
                            <span v-if="!$v.property.equityValue.required">É necessário o Valor Patrimonial do Imóvel</span>
                            <span v-if="errors.EquityValue">{{errors.EquityValue}}</span>
                        </div>
                    </div>
                </div>
            <!-- Action button + loading -->  
            <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
            <CButton v-else type="button" color="primary" class="btn align-self-end text-light mt-5" @click="submitClicked">Guardar</CButton>
        </CCardBody>
    </CCol>
</div>
</template>
 
<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import Property from "@/classes/Property";
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import EventBus from '@/events/bus.js';
import Datepicker from 'vuejs-datepicker';
var moment = require('moment');

export default {
    components: {
        Datepicker,
    },
    props:{    
        baseProperty:
        {
            type: Object,
            default: null,
        }
    },
    data()
    {
        return {
            submitted: false,
            property: new Property(),
            loading: false
        }
    },
    validations: {
        property: {
            ownership: { required },
            address: { required },
            equityValue: { required },
            financingAmount: { required },
            financingYears: { required },
            initialCost: { required },
            name: { required },
            purchaseAmount: { required },
            totalFinancing: { required },
            purchaseDate: { required },
        },
    },
    mounted: function()
    {
        this.resetProperty();
    },
    computed:
    {
        ...mapState('properties', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        }
    },
    methods:{
        resetProperty(){
            if(!this.baseProperty)
            {
                this.property = new Property();
            }
            else
            {
                this.property = UTILS_HELPER.deepClone(new Property, this.baseProperty); 
                this.property.purchaseDate = new Date(this.property.purchaseDate).toISOString().substr(0, 10);
            }
        },
        customFormatter(date) {
            moment.locale('pt');
            return moment(date).format(`DD/MM/YYYY`);
        },
        submitClicked(){
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid || !this.property.isValid()) {
                return;
            }

            this.loading = true;
            this.$emit('submit-requested', this.property);
        },
        hasError(fieldName)
        {
            return this.$v.property[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        goToProperties(){
            this.$router.push({name: "PropertiesList"});
        }
    },
    watch:
    {
        processTask: function(val)
        {
            if(val != null)
            {
                if(val)
                {
                    EventBus.$emit("success-alert-requested", { message: 'Executado com sucesso!', primaryButton: 'Confirmar',  primaryCallback: this.goToProperties});
                }
                this.loading = false;
            }
        },
        baseProperty: function()
        {
            this.resetProperty();
        },
        alertOutput: function()
        {
            // Show pop up in case it is a string error
            // In case it is an array, it is shown on the field
            if(typeof this.alertOutput === 'string' || this.alertOutput instanceof String)
            {
                alert(this.alertOutput);
            }
        }
    }
  }
</script> 
 
<style scoped>
    .label-properties{
        color:rgb(78, 84, 200);
        font-weight: bold;
    }

    #ownership-selector > span{
        color:gray;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector > span.active{
        color:rgb(143, 148, 251) ;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector.is-invalid > span
    {
        color: #dc3545;
    }
    .button{
        width: 100px;
    }
    >>>.vdp-datepicker input{
        border: none;
    }
    >>>.vdp-datepicker input:focus{
        outline: none;
    }
</style> 