export default class Property
{
  constructor(ownership, address, equityValue, financingAmount, financingYears, initialCost, name, purchaseAmount, totalFinancing, purchaseDate) 
  {
    this.ownership = ownership; 
    this.address = address;
    this.equityValue = equityValue;
    this.financingAmount = financingAmount;
    this.financingYears = financingYears;
    this.initialCost = initialCost;
    this.name = name;
    this.purchaseAmount = purchaseAmount;
    this.totalFinancing = totalFinancing;
    this.purchaseDate = purchaseDate;
  }    

  isValid()
  {
    return this.ownership && this.address && this.equityValue && this.financingAmount && this.financingYears && this.initialCost && this.name && this.purchaseAmount && this.totalFinancing && this.purchaseDate;
  }
}